<!-- Unit, Quantity and Trade Offer of Products -->
<div
  class="modal fade"
  [ngClass]="{'active': showQuantityModal}"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content top_border">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Product <span> Detail</span>
        </h5>
        <button
          type="button"
          class="close dont-close-products"
          data-dismiss="modal"
          (click)="closeQuantityModal($event)"
          aria-label="Close"
        >
          <span class="dont-close-products" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        *ngIf="selectedProduct.hasOwnProperty('quantity')"
        class="modal-body AddDetailPR"
      >
        <div class="row">
          <div class="col-md-4 pt-7 mb-15">Add Quantity:</div>
          <div class="col-md-8 mb-15">
            <input
              type="text"
              class="form-control"
              placeholder=""
              (keyup)="setQuantity(selectedProduct);checkAvailableQty(selectedProduct)"
              required
              (keydown)="isNumber($event, 'quantity')"
              [(ngModel)]="selectedProduct.stockQty"
              #selectedQty="ngModel"
              style="font-size: 13px"
            />
            <span
              *ngIf="selectedQty.errors?.required && isAdded"
              class="input-error"
            >
              Please add Quantity!
            </span>
            <span
              *ngIf="+selectedProduct.stockQty === 0 && isAdded && !selectedQty.errors?.required"
              class="input-error"
            >
              Quantity should be greater than 0!
            </span>
          </div>
          <div class="col-md-4 pt-7 mb-15">Unit:</div>
          <div class="col-md-8 mb-15">{{selectedProduct.unit_name}}</div>
          <div
            class="col-md-4"
            *ngIf="selectedProduct.schemes.length"
            style="padding-top: 2px"
          >
            Trade Offer:
          </div>
          <div class="col-md-8">
            <div *ngFor="let scheme of selectedProduct.schemes;let i=index">
              <div class="custom-control custom-radio">
                <input
                  [(ngModel)]="selectedProduct.selectedScheme"
                  (click)="selectedProduct.pref_id && selectedProduct.quantity && calculateProductDiscounts(selectedProduct, scheme)"
                  class="custom-control-input"
                  type="radio"
                  name="AssignCatalogue"
                  id="byTerritory-{{i}}"
                  [value]="scheme"
                />
                <label
                  class="custom-control-label font13"
                  for="byTerritory-{{i}}"
                  style="line-height: 25px"
                  >{{scheme.title}}</label
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer border-0" style="background-color: #f6f6f6">
        <button
          type="submit"
          class="btn btn-primary dont-close-products"
          (click)="addProductToOrder()"
        >
          Confirm
        </button>
        <button
          type="button"
          id="pl-qty-close"
          class="btn btn-cancel dont-close-products"
          data-dismiss="modal"
          (click)="closeQuantityModal($event)"
          aria-label="Close"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Products listing -->
<div id="product-cl-sec" class="active" (clickOutside)="clickedOutSide($event)">
  <a id="pl-close" (click)="closeProductsList()" class="close-btn-pl"></a>
  <div class="pro-header-text">Add <span>Product</span></div>
  <div class="se_cus-type form-wrap p-15">
    <div class="row">
      <div class="col-12">
        <div class="productSearch">
          <i class="fa fa-search"></i>
          <input
            type="text"
            class="form-control"
            id=""
            [(ngModel)]="productSearchText"
            (keyup)="searchProduct()"
            placeholder="Search"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="pc-cartlist">
    <div class="overflow-plist">
      <div class="plist-content">
        <div class="_left-filter pt-0">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="card top_border p-15">
                  <table
                    *ngIf="!loadingProducts"
                    class="AddProductTable"
                    width="100%"
                  >
                    <tbody>
                      <ng-container
                        *ngFor="let product of dispProducts; let i=index"
                      >
                        <tr>
                          <td>
                            <div class="ProListDiv">
                              <div
                                *ngIf="product.schemes?.length"
                                class="lab-promotion"
                              >
                                Scheme
                              </div>
                              <img
                                class="PrList_img"
                                src="{{product.thumbnail}}"
                                alt=""
                              />
                              <div class="PR_Name">
                                {{product.item_name}}
                                <span
                                  style="
                                    display: block;
                                    opacity: 0.6;
                                    padding-top: 5px;
                                  "
                                >
                                  SKU: {{ product.item_sku }}
                                </span>
                                <span
                                  *ngIf="orderType === 'execution'"
                                  style="display: block"
                                  >available: {{product.available_qty}}</span
                                >
                                <span
                                  *ngIf="product?.current_load_allocated_qty"
                                  style="display: block; opacity: 0.6"
                                >
                                  available:
                                  {{product?.current_load_allocated_qty -
                                  product?.current_load_booked_qty}}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <button
                              *ngIf="!product.isAdded"
                              class="btn btn-default mb-0 dont-close-quantity"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              (click)="openQuantityModal(product)"
                            >
                              Add
                            </button>
                            <span
                              *ngIf="product.isAdded "
                              class="btn btn-added mb-0 dont-close-quantity"
                              >Added</span
                            >
                            <!-- <span *ngIf="product.isDeleted"
                                                            class="btn btn-added mb-0 dont-close-quantity">Cancelled</span> -->
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                  <div class="col-md-12 loader" *ngIf="loadingProducts">
                    <h2 class="loading">Loading Products...</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="_cl-bottom">
    <button
      id="pl-prod-close"
      type="butotn"
      (click)="closeProductsList()"
      class="btn btn-cancel mr-2"
    >
      Cancel
    </button>
  </div>
</div>
