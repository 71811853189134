import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ToasterService } from 'src/app/core/services/toaster.service';

@Component({
  selector: 'app-orders-sub-list',
  templateUrl: './orders-sub-list.component.html',
  styleUrls: ['./orders-sub-list.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class OrdersSubListComponent implements OnInit, OnChanges {
  @Input() loading = false;
  @Input() retailers = new Array<any>();
  @Input() currentTab: number;
  retailerDispList = new Array<any>();

  searchText: string;
  @Output() retailerChanged: EventEmitter<any> = new EventEmitter();

  constructor(
    private toastService: ToasterService,
    private change: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    this.retailerDispList = this.retailers;
    this.change.detectChanges();
  }

  searchByRetailer(): void {
    if (this.searchText) {
      this.retailerDispList = this.retailers.filter((ret) =>
        ret.retailer_name.toLowerCase().includes(this.searchText.toLowerCase())
      );
    } else {
      this.retailerDispList = this.retailers;
    }
  }
  getOrderDetails(retailer: any): void {
    this.retailerDispList.forEach((ret) => {
      ret.isActive = false;
      if (ret.retailer_id === retailer.retailer_id) {
        ret.isActive = true;
      }
    });

    this.retailerChanged.emit(retailer);
  }
}
